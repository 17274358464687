/* ContactSection.module.css */

/* General Styles */
.successMessage {
  height: 30px;
}

.formPage {
  padding: 80px;
  background-color: #f3f5f6; /* Light gray background */
  font-family: oswald;
}

.formSection {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1200px;
  margin: 0 auto;
}

.form-container {
  flex: 1;
  background-color: #1d1e4f; /* Dark blue background */
  padding: 30px 60px 60px 60px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.form-container h1 {
  text-align: center;
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #ffc700; /* Yellow text */
}

.form-container form {
  display: flex;
  flex-direction: column;
}

.form-container label {
  font-size: 1.2em;
  margin-bottom: 5px;
  color: #f3f5f6; /* Light gray text */
}

.form-container input,
.form-container textarea {
  font-size: 1em;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #000;
}

.form-container button {
  padding: 10px 20px;
  margin-top: 1em;
  font-size: 1.4em;
  font-weight: bold;
  color: #1d1e4f; /* Dark blue text */
  background-color: #ffc700; /* Yellow background */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: oswald;
}

.form-container button:hover {
  background-color: #f3f5f6; /* Light gray background on hover */
}

.contact-info {
  flex: 1;
  margin-left: 8em;
}

.contact-info h2 {
  font-size: 3em;
  margin-bottom: 20px;
  color: #1d1e4f; /* Dark blue text */
}

.contact-info p {
  font-size: 1.6em;
  color: #447e97; /* Teal text */
}

.successMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.successMessage p {
  font-size: 1.45em;
}

.errorMessage {
  height: 30px;
  margin: 0;
}

.errorMessage p {
  margin: 0;
  color: red;
  font-size: 1.2em;
}

/* Media Queries for Responsiveness */

/* For tablets and small screens */
@media (max-width: 768px) {
  .formPage {
    padding: 40px 20px; /* Reduce padding */
  }

  .formSection {
    flex-direction: column;
    align-items: center;
    gap: 40px; /* Space between form and contact info */
  }

  .form-container {
    padding: 20px;
    width: 50%;
  }

  .form-container h1 {
    font-size: 2em; /* Adjust title font size */
  }

  .form-container label {
    font-size: 1em; /* Adjust label font size */
  }

  .form-container input,
  .form-container textarea {
    font-size: 0.9em; /* Adjust input font size */
  }

  .form-container button {
    font-size: 1.2em; /* Adjust button font size */
  }

  .contact-info {
    margin-left: 0;
    text-align: center; /* Center align contact info */
  }

  .contact-info h2 {
    font-size: 2em; /* Adjust heading font size */
  }

  .contact-info p {
    font-size: 1.4em; /* Adjust paragraph font size */
  }
}

/* For mobile screens */
@media (max-width: 480px) {
  .formPage {
    padding: 20px; /* Further reduce padding */
  }

  .form-container {
    padding: 15px;
    width: 90%; /* Increase width for very small screens */
  }

  .form-container h1 {
    font-size: 1.6em; /* Adjust title font size */
  }

  .form-container label {
    font-size: 0.9em; /* Adjust label font size */
  }

  .form-container input,
  .form-container textarea {
    font-size: 0.8em; /* Adjust input font size */
  }

  .form-container button {
    font-size: 1em; /* Adjust button font size */
  }

  .contact-info {
    margin-left: 0;
    text-align: center; /* Center align contact info */
  }

  .contact-info h2 {
    font-size: 1.6em; /* Adjust heading font size */
  }

  .contact-info p {
    font-size: 1.2em; /* Adjust paragraph font size */
  }
}
