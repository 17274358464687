/* Header styles */
.header {
  background-color: #1d1e4f;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.75em 2.5em;
  color: #ffffff;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.logo {
  font-size: 1.5em;
  font-weight: bold;
  color: #ffc700; /* Yellow */
  font-family: oswald;
}

.nav ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.nav ul li {
  margin: 0 15px;
  font-family: oswald;
  font-size: 1.2em;
}

.nav ul li a {
  text-decoration: none;
  color: #f3f5f6; /* Light gray */
  font-size: 1.1em;
  transition: color 0.3s ease;
}

.nav ul li a:hover {
  color: #447e97; /* Teal */
}

.logo a {
  color: inherit; /* Inherit color from parent */
  text-decoration: none;
}

.logo a:visited {
  color: inherit;
}

/* Media Queries for Responsiveness */

/* For tablets and small screens */
@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: center; /* Center-align the items */
    padding: 1.5em;
  }

  .logo {
    margin-bottom: 1em; /* Add margin for spacing */
  }

  .nav ul {
    flex-direction: row; /* Keep nav items horizontal */
    justify-content: center;
    width: 100%;
  }

  .nav ul li {
    margin: 10px 15px; /* Adjust margin */
    font-size: 1em;
  }

  .nav ul li a {
    font-size: 1em;
  }
}

/* For mobile screens */
@media (max-width: 480px) {
  .header {
    padding: 1em;
  }

  .logo {
    font-size: 1.2em;
    margin-bottom: 0.75em; /* Add margin for spacing */
  }

  .nav ul {
    flex-direction: row; /* Keep nav items horizontal */
    justify-content: center;
    width: 100%;
  }

  .nav ul li {
    margin: 8px 10px; /* Adjust margin */
    font-size: 0.9em;
  }

  .nav ul li a {
    font-size: 0.9em;
  }
}
