/* Styles for ServicesSection */
.services {
  background-color: #1d1e4f; /* Dark blue background */
  padding: 60px 20px; /* Top and bottom padding */
  font-family: oswald;
}

.container {
  max-width: 1200px; /* Maximum width of the section */
  margin: 0 auto; /* Center align container */
}

.title {
  font-size: 2.5em; /* Size of the section title */
  margin-bottom: 40px; /* Space below the title */
  color: #ffc700; /* Yellow text */
  text-align: center; /* Center align the section title */
}

.servicesList {
  display: flex;
  flex-wrap: wrap; /* Wrap items on smaller screens */
  justify-content: center; /* Center align items */
  gap: 20px; /* Space between items */
}

.serviceCategory {
  flex: 1;
  min-width: 300px; /* Minimum width of each service category */
  background-color: #ffffff; /* White background for each item */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  padding: 20px;
  text-align: left; /* Left align text within the category box */
}

.categoryTitle {
  font-size: 1.8em; /* Font size of the service category title */
  margin-bottom: 15px; /* Space below the title */
  color: #9a715b; /* Teal text */
  text-align: center; /* Center align the category title */
}

.serviceList {
  list-style-type: disc; /* Bullet points */
  padding-left: 20px; /* Space between bullets and text */
  margin: 0; /* Remove default margin */
}

.serviceItem {
  font-size: 1.4em; /* Font size of the service item description */
  color: #447e97; /* Teal text */
  margin-bottom: 10px; /* Space below each item */
}

.greenCleaning {
  background-color: #ffffff; /* White background for green cleaning section */
  padding: 60px 20px; /* Top and bottom padding */
  text-align: center; /* Center align text */
  font-family: oswald;
}

.greenTitle {
  font-size: 2.5em; /* Size of the green cleaning section title */
  margin-bottom: 20px; /* Space below the title */
  color: #1d1e4f; /* Dark blue text */
}

.greenText {
  font-size: 1.4em; /* Font size of the description */
  color: #447e97; /* Teal text */
  margin-bottom: 30px; /* Space below the description */
}

.greenServicesList {
  max-width: 800px;
  margin: 0 auto;
}

.greenSubtitle {
  font-size: 1.8em; /* Font size of the green services subtitle */
  margin-bottom: 15px; /* Space below the subtitle */
  color: #ffc700; /* Yellow text */
  text-align: center; /* Center align the green services subtitle */
}

.ecoClean {
  width: 100%; /* Make the image responsive */
  max-width: 600px; /* Set a maximum width for larger screens */
  height: auto; /* Maintain aspect ratio */
  margin: 0 auto; /* Center align the image */
}

.testimonials {
  text-align: center;
  margin: 60px auto;
  color: #fff;
  width: 40%; /* Full width */
}

.testimonials h3 {
  font-size: 2em;
  margin-bottom: 20px;
}

.swiper-slide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.testimonial {
  font-style: italic;
  margin-bottom: 20px;
  font-size: 1.25em;
}

.testimonial footer {
  font-style: normal;
  font-weight: bold;
  margin-top: 10px;
  color: #ffffff; /* Dark blue text */
}

.swiper-button-next,
.swiper-button-prev {
  color: #007bff; /* Customize color */
}

.swiper-pagination-bullet {
  background: #007bff; /* Customize color */
}

.clientSubtitle {
  color: #ffc700; /* Yellow text */
}

/* Media Queries for Responsiveness */

/* For tablets and small screens */
@media (max-width: 768px) {
  .services {
    padding: 40px 10px; /* Reduced padding */
  }

  .title {
    font-size: 2em; /* Adjust font size */
  }

  .serviceCategory {
    flex: 1;
    min-width: 280px; /* Smaller minimum width */
    padding: 15px;
  }

  .categoryTitle {
    font-size: 1.6em; /* Adjust font size */
  }

  .serviceItem {
    font-size: 1.2em; /* Adjust font size */
  }

  .greenTitle {
    font-size: 2em; /* Adjust font size */
  }

  .greenText {
    font-size: 1.2em; /* Adjust font size */
  }

  .greenSubtitle {
    font-size: 1.6em; /* Adjust font size */
  }

  .ecoClean {
    max-width: 90%; /* Make the image more responsive */
  }
  .testimonial footer {
    color: #ffffff;
  }

  .testimonials {
    width: 100%;
  }
}

/* For mobile screens */
@media (max-width: 480px) {
  .services {
    padding: 20px 10px; /* Further reduced padding */
  }

  .title {
    font-size: 1.8em; /* Adjust font size */
  }

  .serviceCategory {
    min-width: 100%; /* Full width */
    padding: 10px;
  }

  .categoryTitle {
    font-size: 1.4em; /* Adjust font size */
  }

  .serviceItem {
    font-size: 1em; /* Adjust font size */
  }

  .greenTitle {
    font-size: 1.8em; /* Adjust font size */
  }

  .greenText {
    font-size: 1em; /* Adjust font size */
  }

  .greenSubtitle {
    font-size: 1.4em; /* Adjust font size */
  }

  .ecoClean {
    max-width: 100%; /* Full width for the image */
  }
  .testimonial footer {
    color: #ffffff;
  }

  .testimonials {
    width: 100%;
  }
}
