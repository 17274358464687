/* AboutSection.module.css */

.about {
  padding: 40px;
  background-color: #f3f5f6; /* Light gray background */
  font-family: oswald;
}

.container {
  max-width: 1200px; /* Maximum width of the section */
  margin: 0 auto; /* Center align container */
}

.title {
  text-align: center;
  font-size: 2.5em; /* Size of the section title */
  margin-bottom: 20px; /* Space below the title */
  color: #1d1e4f; /* Dark blue text */
}

.content {
  text-align: center;
  color: #447e97; /* Teal text */
}

.overview {
  font-size: 1.8em; /* Font size of the overview text */
  margin-bottom: 30px; /* Space below the text */
}

.subtitle {
  font-size: 2em; /* Font size of the subtitle */
  margin-bottom: 20px; /* Space below the subtitle */
  color: #ffc700; /* Yellow text */
}

.USP {
  margin-bottom: 30px; /* Space below the USP section */
}

.USPList {
  list-style-type: disc; /* Bullet points */
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
  text-align: left; /* Align text to the left */
  display: inline-block; /* Center align the list */
}

.USPItem {
  margin-bottom: 10px; /* Space below each item */
  font-size: 1.4em; /* Font size of the USP items */
}

.aboutUsImage {
  width: 100%; /* Make the image responsive */
  max-width: 600px; /* Set a maximum width for the image */
  height: auto; /* Maintain aspect ratio */
  margin: 20px 0; /* Margin above and below the image */
}

/* Media Queries for Responsiveness */

/* For tablets and small screens */
@media (max-width: 768px) {
  .about {
    padding: 30px 20px; /* Adjust padding */
  }

  .title {
    font-size: 2em; /* Adjust font size */
  }

  .overview {
    font-size: 1.5em; /* Adjust font size */
    margin-bottom: 20px; /* Adjust margin */
  }

  .subtitle {
    font-size: 1.6em; /* Adjust font size */
  }

  .USPItem {
    font-size: 1.2em; /* Adjust font size */
  }

  .aboutUsImage {
    max-width: 90%; /* Make the image more responsive */
  }
}

/* For mobile screens */
@media (max-width: 480px) {
  .about {
    padding: 20px 10px; /* Further reduce padding */
  }

  .title {
    font-size: 1.6em; /* Adjust font size */
  }

  .overview {
    font-size: 1.2em; /* Adjust font size */
    margin-bottom: 15px; /* Adjust margin */
  }

  .subtitle {
    font-size: 1.4em; /* Adjust font size */
  }

  .USPItem {
    font-size: 1em; /* Adjust font size */
  }

  .aboutUsImage {
    max-width: 100%; /* Full width for the image */
  }
}
