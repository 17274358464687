.landing {
  background-size: cover;
  background-position: center;
  height: 100vh; /* Full height of the viewport */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.overlay {
  background-color: rgba(29, 30, 79, 0.8); /* Navy blue with opacity */
  padding: 20px 80px;
  border-radius: 8px;
  display: flex;
  align-items: center; /* Center align items vertically */
  justify-content: space-between; /* Space between logo and text/button */
  width: 80%; /* Adjust width as needed */
  max-width: 80%;
  height: 40%; /* Adjust height as needed */
  max-height: 50%;
  position: relative; /* Allow positioning of the button */
}

.logoImage {
  height: 20em; /* Adjust size as needed */
  width: auto;
  margin-right: 20px; /* Space between logo and text/button */
}

.textAndButton {
  display: flex;
  flex-direction: column; /* Stack text and button vertically */
  justify-content: center; /* Center align text and button vertically */
  color: #ffffff !important;
  width: 100%; /* Take full width of its container */
  position: relative; /* Allow absolute positioning within this container */
}

.text {
  font-family: oswald;
}

.text h1 {
  font-size: 2em; /* Adjust font size as needed */
  margin-bottom: 10px;
}

.text p {
  font-size: 1.5em; /* Adjust font size as needed */
  padding-bottom: 3em;
}

.contactButton {
  position: absolute; /* Position it absolutely within textAndButton */
  bottom: 0; /* Align to the bottom */
  right: 0; /* Align to the right */
  padding: 15px 25px;
  background-color: #ffc700; /* White background for contrast */
  color: #1d1e4f; /* Navy blue text */
  font-weight: bold;
  border: none;
  border-radius: 5px;
  font-size: 1.2em; /* Adjust size as needed */
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add subtle shadow for better visibility */
  font-family: oswald;
}

.contactButton:hover {
  background-color: #ffffff;
}

@media (max-width: 768px) {
  /* .overlay {
    flex-direction: column;
    padding: 20px;
    width: auto;
    height: 10%;
  } */

  .overlay {
    background-color: rgba(29, 30, 79, 0.8); /* Navy blue with opacity */
    padding: 20px 80px;
    border-radius: 8px;
    display: flex;
    align-items: center; /* Center align items vertically */
    justify-content: space-between; /* Space between logo and text/button */
    width: 80%; /* Adjust width as needed */
    height: 100vh; /* Adjust height as needed */
    max-height: 50%;
    position: relative; /* Allow positioning of the button */
  }
  .textAndButton {
    display: flex;
    flex-direction: column; /* Stack text and button vertically */
    justify-content: center; /* Center align text and button vertically */
    color: #ffffff !important;
    width: 100%; /* Take full width of its container */
    position: relative;
    height: ; /* Allow absolute positioning within this container */
  }

  .logoImage {
    height: 10em;
    margin-right: 0;
  }

  .text {
    text-align: center; /* Center text */
    margin-bottom: 0;
  }

  .text h1 {
    font-size: 2em; /* Adjust font size as needed */
  }

  .text p {
    font-size: 1.5em; /* Adjust font size as needed */
  }

  .contactButton {
    position: static; /* Remove absolute positioning */
    align-self: center; /* Center button horizontally */
  }
}

/* For mobile screens */
@media (max-width: 480px) {
  .landing {
    height: 75vh;
  }
  .overlay {
    padding: 15px 15px 335px 15px;
    width: 95%;
    max-width: 95%;
    flex-direction: column;
  }

  .logoImage {
    height: 13em; /* Adjust size as needed */
  }

  .text h1 {
    font-size: 1.75em; /* Adjust font size as needed */
  }

  .text p {
    font-size: 1.2em; /* Adjust font size as needed */
    padding-bottom: 0;
  }
  .contactButton {
    padding: 10px;
    font-size: 1em;
  }
}

@media (max-width: 380px) {
  .landing {
    height: 75vh;
  }
  .overlay {
    padding: 15px 15px 335px 15px;
    width: 95%;
    max-width: 95%;
    flex-direction: column;
  }

  .logoImage {
    height: 13em; /* Adjust size as needed */
  }

  .text h1 {
    font-size: 1.25em; /* Adjust font size as needed */
  }

  .text p {
    font-size: 1em; /* Adjust font size as needed */
    padding-bottom: 0;
  }
  .contactButton {
    padding: 10px;
    font-size: 1em;
  }
}
