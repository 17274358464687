/* Footer.module.css */

.footer {
  background-color: #1d1e4f;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.75em 2.5em;
  color: #ffffff;
  position: relative;
  bottom: 0;
}

.footerLogo {
  font-size: 1.5em;
  font-weight: bold;
  color: #ffc700; /* Yellow */
  font-family: oswald;
}

.footerNav ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.footerNav ul li {
  margin: 0 15px;
  font-family: oswald;
  font-size: 1.2em;
}

.footerNav ul li a {
  text-decoration: none;
  color: #f3f5f6; /* Light gray */
  font-size: 1.1em;
  transition: color 0.3s ease;
}

.footerNav ul li a:hover {
  color: #447e97; /* Teal */
}

.footerBottom {
  text-align: center;
  margin-top: 20px;
  font-family: oswald;
  font-size: 1em;
  color: #f3f5f6; /* Light gray */
}

.footerLogo a {
  color: inherit; /* Inherit color from parent */
  text-decoration: none;
}

.footerLogo a:visited {
  color: inherit;
}

@media (max-width: 768px) {
  .footer {
    padding: 1.5em 1.5em; /* Adjust padding */
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center align items */
  }

  .footerLogo {
    font-size: 1.2em; /* Adjust font size */
    margin-bottom: 1em; /* Add margin for spacing */
  }

  .footerNav ul {
    flex-direction: row; /* Keep nav items horizontal */
    justify-content: center; /* Center align nav items */
    width: 100%;
  }

  .footerNav ul li {
    margin: 10px 15px; /* Adjust margin */
    font-size: 1em; /* Adjust font size */
  }

  .footerNav ul li a {
    font-size: 1em; /* Adjust font size */
  }

  .footerBottom {
    font-size: 0.9em; /* Adjust font size */
    margin-top: 1em; /* Add margin for spacing */
  }
}

@media (max-width: 480px) {
  .footer {
    padding: 1em 1em; /* Further adjust padding */
  }

  .footerLogo {
    font-size: 1em; /* Adjust font size */
    margin-bottom: 0.75em; /* Add margin for spacing */
  }

  .footerNav ul {
    flex-direction: row; /* Keep nav items horizontal */
    justify-content: center; /* Center align nav items */
    width: 100%;
  }

  .footerNav ul li {
    margin: 8px 10px; /* Adjust margin */
    font-size: 0.9em; /* Adjust font size */
  }

  .footerNav ul li a {
    font-size: 0.9em; /* Adjust font size */
  }

  .footerBottom {
    font-size: 0.8em; /* Adjust font size */
    margin-top: 0.75em; /* Add margin for spacing */
  }
}
